.App {
    width: 360px;
    margin: 0 auto;
    padding: 1rem;
    text-align: center;
    font-family: 'Roboto', sans-serif;

    input {
        position: absolute;
        left: -300rem;
    }

    a {
        text-decoration: none;
        color: black;
    }

    button {
        background: none;
        border: none;
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
    }

    h2 {
        margin: 0;
        font-size: 6rem;
        font-style: normal;
        font-weight: 700;
        opacity: 0.05;
    }

    article {
        padding: 0 1rem;
    }

    img {
        width: 100%;
    }

    .nav {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;

        &-item {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            padding: 0.5rem;
            cursor: pointer;
        }

        &-btn {
            display: flex;
            gap: 0.75rem;
        }

        > div {
            display: flex;
            align-items: center;
        }
    }

    .text {
        margin: 1rem 0;
        line-height: 1.4rem;
    }

    .google {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.75rem;
        margin: 0 auto;
    }

    .slick-slider {
        margin-top: 2.5rem;
    }

    @media only screen and (min-height: 680px) {
        h2 {
            margin-top: 1.5rem;
            margin-bottom: 2rem;
        }

        .text {
            margin: 2rem 0;
        }
    }

    @media only screen and (min-height: 768px) {
        .nav {
            margin-top: 2rem;
        }
    }
}
